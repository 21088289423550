import * as React from "react"
import Checkbox, { CheckboxProps } from "@mui/material/Checkbox"
import { styled } from "@mui/material/styles"

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: 3,
  width: 18,
  height: 18,
  backgroundColor: "transparent",
  border: "1px solid #2F8067",
  "input:hover ~ &": {
    backgroundColor: "#ebf1f5",
    ...theme.applyStyles("dark", {
      backgroundColor: "#30404d",
    }),
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background: "rgba(206,217,224,.5)",
    ...theme.applyStyles("dark", {
      background: "rgba(57,75,89,.5)",
    }),
  },
  ...theme.applyStyles("dark", {
    boxShadow: "0 0 0 1px rgb(16 22 26 / 40%)",
    backgroundColor: "#394b59",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))",
  }),
}))

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "transparent",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&::before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage: `url("data:image/svg+xml;charset=utf-8,${encodeURIComponent(`
<svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M4.01328 8.08151L1.32095 5.32207C1.01835 5.01193 0.529543 5.01193 0.226947 5.32207C-0.0756491 5.63221 -0.0756491 6.1332 0.226947 6.44334L3.47016 9.7674C3.77275 10.0775 4.26156 10.0775 4.56416 9.7674L12.7731 1.35388C13.0756 1.04374 13.0756 0.542744 12.7731 0.232604C12.4705 -0.0775348 11.9816 -0.0775348 11.679 0.232604L4.01328 8.08151Z" fill="#2F8067"/>
</svg>
`)}")`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "2px 3px",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "transparent",
  },
})

export const CustomCheckbox = (props: CheckboxProps): JSX.Element => {
  return (
    <Checkbox
      sx={{ "&:hover": { bgcolor: "transparent" } }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      inputProps={{ "aria-label": "Checkbox demo" }}
      {...props}
    />
  )
}
