import { Question } from "@app/model/questions"
import {
  QueryFunction,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query"

import { API_V1, buildAuthHeader, REST_URL_CHATBOT } from "../api"
import { QueryKey } from "../queryKey"
import { useAuthenticatedQuery } from "../useAuthenticatedQuery"

type QueryResultData = Question
type QueryKeyType = ReturnType<typeof QueryKey.questionById>

export const getQuestion: QueryFunction<
  QueryResultData,
  QueryKeyType
> = async ({ queryKey, meta }): Promise<QueryResultData> => {
  const [_, { id }] = queryKey
  const token = meta?.token as string

  const questionPath = `${REST_URL_CHATBOT}${API_V1}/questions/${id}`

  const response = await fetch(questionPath, {
    method: "GET",
    headers: {
      ...buildAuthHeader(token),
    },
  })

  if (!response.ok) {
    throw new Error("Failed to fetch question")
  }

  const results = (await response.json()) as Question
  return results
}

export const useQuestionQuery = (
  id: string,
  options?: Omit<
    UseQueryOptions<Question, Error, Question, QueryKeyType>,
    "queryKey"
  >,
): UseQueryResult<Question, Error> => {
  const queryKey = QueryKey.questionById(id)

  return useAuthenticatedQuery<Question, Error, QueryKeyType>(
    queryKey,
    getQuestion,
    options,
  )
}
