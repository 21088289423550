import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useInvitationGenerateLinkQuery } from "@app/api/invitation/useInvitationGenerateLinkQuery"
import { AddUserIcon, CopyLinkIcon } from "@app/assets/Icons"
import { useChatbotContext } from "@app/context/ChatbotContext"
import { Alert, Snackbar, SnackbarCloseReason } from "@mui/material"

import AddUserModal from "./AddUserModal"
import UsersPanel from "./UsersPanel"

const UserManagement = (): JSX.Element => {
  const { t } = useTranslation()
  const [isUserModalOpen, setUserModalOpen] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const { chatbotName, tenantName } = useChatbotContext()

  const { mutateAsync: mutateGenerateLink } = useInvitationGenerateLinkQuery()

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason,
  ): void => {
    if (reason === "clickaway") {
      return
    }

    setOpenSnackbar(false)
  }

  const handleOnCopyLink = async (): Promise<void> => {
    if (isDisabled) return
    try {
      setIsDisabled(true)
      const response = await mutateGenerateLink({
        role_id: chatbotName,
        tenant_name: tenantName,
        role_type: "admin",
        chatbots: [chatbotName],
      })
      await navigator.clipboard.writeText(response.link)
      setOpenSnackbar(true)
    } catch (err) {
      console.error("Failed to copy invitation link: ", err)
    } finally {
      setTimeout(() => {
        setIsDisabled(false)
      }, 2000)
    }
  }

  return (
    <>
      <div className="grid h-full gap-4 p-4 sm:grid-flow-col sm:grid-cols-10 md:grid-cols-4">
        <div className="flex flex-col text-center sm:col-span-3 md:col-span-1">
          <div className="flex flex-1 justify-center items-center border-color-1 p-6 bg-grey-50 rounded-3xl border mb-4">
            <button
              type="button"
              disabled={isDisabled}
              className="flex flex-col items-center text-2xl cursor-pointer border-none bg-transparent"
              onClick={async () => handleOnCopyLink()}
            >
              <CopyLinkIcon
                className={isDisabled ? "stroke-grey-100" : "stroke-grey-900"}
              />
              {t("user_management.copy_invitation_link.title")}
            </button>
          </div>
          <div className="flex flex-1 justify-center items-center border-color-1 p-6 bg-grey-50 rounded-3xl border">
            <div
              className="flex flex-col items-center text-2xl cursor-pointer"
              onClick={() => setUserModalOpen(true)}
            >
              <AddUserIcon />
              {t("user_management.add_user.title")}
            </div>
          </div>
        </div>

        <UsersPanel />
      </div>

      <AddUserModal isOpen={isUserModalOpen} setOpen={setUserModalOpen} />

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{
            width: "100%",
            backgroundColor: "#3EAA89",
          }}
        >
          {"Invitation copied to clipboard"}
        </Alert>
      </Snackbar>
    </>
  )
}

export default UserManagement
