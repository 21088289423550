import { QuestionsParams } from "@app/model/questions"
import { AdminAcceptParams, UsersParams } from "@app/model/user"

const fileList = ["files"]
const userList = ["users"]
const uploadFile = ["upload"]
const downloadFile = ["download"]
const questionList = ["questions"]
const question = ["question"]

export const questionById = (id: string): ["question", { id: string }] => {
  return ["question", { id }]
}

export const validateToken = (
  token: string,
): ["validation", { token: string }] => {
  return ["validation", { token }]
}

export const flowType = (
  email: string,
  invitationToken: string,
): ["flowType", { email: string; invitationToken: string }] => {
  return ["flowType", { email, invitationToken }]
}

export const adminUserCreation = (
  credentials: AdminAcceptParams,
  invitationToken: string,
): [
  "adminUserCreation",
  { credentials: AdminAcceptParams; invitationToken: string },
] => {
  return ["adminUserCreation", { credentials, invitationToken }]
}

export const userDetails = (
  role_id: string,
): ["userDetails", { role_id: string }] => {
  return ["userDetails", { role_id }]
}

export const questionsList = (
  chatbotName: string,
  params: QuestionsParams,
): ["questions", string, QuestionsParams] => {
  return ["questions", chatbotName, params]
}
export const usersList = (
  chatbotName: string,
  tenantName: string,
  params: UsersParams,
): ["users", string, string, UsersParams] => {
  return ["users", chatbotName, tenantName, params]
}

export const filesList = (chatbotName: string): ["files", string] => {
  return ["files", chatbotName]
}

export const QueryKey = {
  fileList,
  userList,
  uploadFile,
  downloadFile,
  questionList,
  question,
  userDetails,
  adminUserCreation,
  flowType,
  validateToken,
  filesList,
  usersList,
  questionsList,
  questionById,
}
