import React from "react"
import { useTranslation } from "react-i18next"
import { useUserCreateQuery } from "@app/api/invitation/useUserCreateQuery"
import { CloseIcon, Email, UsersIcon } from "@app/assets/Icons"
import { useChatbotContext } from "@app/context/ChatbotContext"
import { localizeYupSchema } from "@app/i18n/yup"
import FormSubmitButton from "@app/uiKit/FormSubmitButton"
import {
  Alert,
  Backdrop,
  InputAdornment,
  MenuItem,
  Snackbar,
  SnackbarCloseReason,
  TextField,
} from "@mui/material"
import cn from "classnames"
import { useFormik } from "formik"
import * as yup from "yup"

type AddUserModal = {
  isOpen: boolean
  setOpen: (value: boolean) => void
}

type FormValues = {
  email?: string
  userRole?: string
}

const initialValues: FormValues = {
  email: "",
  userRole: "user",
}

const AddUserModal = ({ isOpen, setOpen }: AddUserModal): JSX.Element => {
  const { t } = useTranslation()

  const [openSnackbar, setOpenSnackbar] = React.useState(false)

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason,
  ): void => {
    if (reason === "clickaway") {
      return
    }

    setOpenSnackbar(false)
  }

  const { chatbotName, tenantName } = useChatbotContext()

  const { data: _, mutateAsync } = useUserCreateQuery()

  const validationSchema = localizeYupSchema<FormValues>(
    t,
    yup.object({
      email: yup.string().email().required(),
    }),
  )

  const handleLogin = async ({
    email,
    userRole,
  }: FormValues): Promise<void> => {
    if (email && userRole) {
      try {
        await mutateAsync({
          email,
          role: userRole,
          tenantName,
          chatbots: [chatbotName],
        })
        setOpen(false)
        setOpenSnackbar(true)
      } catch (e) {
        console.error(e)
      }
    }
  }

  const { values, dirty, errors, isSubmitting, handleSubmit, handleChange } =
    useFormik({
      validationSchema,
      initialValues,
      onSubmit: handleLogin,
      validateOnChange: false,
    })

  const emailIsNotEmpty = values.email

  const errorEmail = errors?.email

  const isSubmitDisabled = !dirty || isSubmitting

  const containerClassName = cn(
    "w-[30%] rounded-[32px] bg-white p-6 sm:py-12 sm:px-16 relative",
  )

  const inputClassName =
    "block border-none h-12 focus:outline-none sm:w-full ml-11 text-grey-900 custom-input autofill:shadow-[inset_0_0_0px_1000px_#ffffff]"

  const inputContainerClassName =
    "relative w-full border-b border-solid border-x-0 border-t-0 mb-3"

  return (
    <>
      <Backdrop
        open={isOpen}
        sx={{
          color: "#24272F",
          zIndex: "1",
          backdropFilter: "blur(2px)",
          backgroundColor: "#0000004d",
        }}
      >
        <div className="flex w-full h-full items-center justify-center">
          <div className={containerClassName}>
            <div
              className="absolute right-[30px] top-[25px] cursor-pointer"
              onClick={() => setOpen(false)}
            >
              <CloseIcon />
            </div>
            <div className="text-xl sm:text-2xl font-medium pb-10">
              {t("user_management.add_user.title")}
            </div>

            <div className="pb-10">
              <>
                <div className="h-3 -mt-1">
                  {emailIsNotEmpty && (
                    <label htmlFor="emailAddress" className="text-xs block">
                      {t("user_management.add_user.email_address")}
                    </label>
                  )}
                </div>
                <div
                  className={cn(inputContainerClassName, {
                    "border-red-500": errorEmail,
                    "border-grey-100": !errorEmail,
                  })}
                >
                  <div className="absolute mt-3 ml-3">
                    <Email
                      className={cn({
                        "stroke-red-500": errorEmail,
                        "stroke-grey-900": !errorEmail,
                      })}
                    />
                  </div>
                  <input
                    name="email"
                    type="email"
                    value={values.email}
                    autoComplete="off"
                    className={cn(inputClassName, {
                      "text-red-500": errorEmail,
                    })}
                    placeholder={t("user_management.add_user.email_address")}
                    onChange={handleChange}
                  />
                </div>
                {errorEmail && (
                  <div
                    className={
                      "min-h-[18px] text-left mt-2 text-red-500 text-xs"
                    }
                  >
                    {errorEmail}
                  </div>
                )}

                <TextField
                  className="w-full"
                  variant="standard"
                  sx={{
                    "& .MuiInputBase-root.MuiInput-root": {
                      height: "48px",
                      fontSize: "14px",
                    },
                    "& .MuiInputBase-root.MuiInput-root.MuiInput-underline::before":
                      {
                        borderBottom: "1px solid #91A3A3",
                      },
                    "& .MuiInputBase-root.MuiInput-root.MuiInput-underline::after":
                      {
                        borderBottom: "1px solid #91A3A3",
                        transform: "none",
                      },
                    "& .MuiSelect-select.MuiSelect-standard.MuiInputBase-input.MuiInput-input:focus":
                      {
                        backgroundColor: "#ffffff",
                      },
                    "& .MuiFormLabel-root.MuiInputLabel-root.Mui-focused": {
                      color: "initial",
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" className="ml-3">
                        <UsersIcon />
                      </InputAdornment>
                    ),
                  }}
                  name="userRole"
                  select
                  value={values.userRole}
                  onChange={handleChange}
                  label={t("user_management.add_user.user_role")}
                >
                  <MenuItem value="user">
                    {t("user_management.add_user.roles.user")}
                  </MenuItem>
                  <MenuItem value="admin">
                    {t("user_management.add_user.roles.admin")}
                  </MenuItem>
                </TextField>
              </>
            </div>
            <FormSubmitButton
              testID="login-next-button"
              onPress={handleSubmit}
              isLoading={isSubmitting}
              isDisabled={isSubmitDisabled}
              text={t("user_management.add_user.title")}
            />
          </div>
        </div>
      </Backdrop>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{
            width: "100%",
            backgroundColor: "#3EAA89",
          }}
        >
          {"The user successfully invited!"}
        </Alert>
      </Snackbar>
    </>
  )
}

export default AddUserModal
