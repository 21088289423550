import {
  QueryFunction,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query"

import { API_V1, REST_URL } from "../api"
import { QueryKey } from "../queryKey"

type TokenValidation = {
  tenantName?: string
  chatbots?: string[]
  email?: string
  linkId?: string
  role: string
}
type QueryResultData = TokenValidation
type QueryKeyType = ReturnType<typeof QueryKey.validateToken>

export const validateToken: QueryFunction<
  QueryResultData,
  QueryKeyType
> = async ({ queryKey }): Promise<QueryResultData> => {
  const [_, { token }] = queryKey
  const validationPath = `${REST_URL}${API_V1}/invitation/token/validate?token=${token}`

  const response = await fetch(validationPath, {
    method: "GET",
  })

  if (!response.ok) {
    throw new Error("Failed to validate token")
  }

  const results = (await response.json()) as TokenValidation
  return results
}

export const useTokenValidationQuery = (
  token: string,
  options?: Omit<
    UseQueryOptions<TokenValidation, Error, TokenValidation, QueryKeyType>,
    "queryKey"
  >,
): UseQueryResult<TokenValidation, Error> => {
  const queryKey = QueryKey.validateToken(token)

  return useQuery<TokenValidation, Error, TokenValidation, QueryKeyType>({
    queryKey,
    queryFn: validateToken,
    ...options,
  })
}
