import React, { useEffect } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { useTokenValidationQuery } from "@app/api/invitation/useTokenValidateQuery"

const AccessPage = (): JSX.Element => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const token = searchParams.get("token")

  useEffect(() => {
    if (!token) {
      navigate("/login")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { isLoading, data } = useTokenValidationQuery(token as string, {
    enabled: Boolean(token),
  })

  useEffect(() => {
    if (data) {
      navigate("/login", {
        state: {
          from: "/access",
          invitationToken: token,
          email: data?.email || "",
        },
      }) // Pass state
    }
  }, [data, navigate, token])

  if (isLoading) return <p>Loading...</p>

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100">
      {!data && (
        <p className="text-2xl font-bold text-red-500">Access Denied</p>
      )}
    </div>
  )
}

export default AccessPage
