import { queryClient } from "@app/App/queryClient"
import { UseMutationOptions, UseMutationResult } from "@tanstack/react-query"

import { API_V1, buildAuthHeader, REST_URL } from "../api"
import { QueryKey } from "../queryKey"
import { useAuthenticatedMutation } from "../useAuthenticatedMutation"

type EditRoleParams = {
  userId: string
  accountType: string
}

const editAccountType = async (
  chatbotName: string,
  userId: string,
  accountType: string,
  token: string,
): Promise<void> => {
  const path = `${REST_URL}${API_V1}/users/role`
  const response = await fetch(path, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      ...buildAuthHeader(token),
    },
    body: JSON.stringify({
      userId,
      externalId: chatbotName,
      accountType,
    }),
  })

  if (!response.ok) {
    throw new Error("Account type edit failed")
  }
}

export const useAccountTypeEditQuery = (
  chatbotName: string,
  options?: UseMutationOptions<void, Error, EditRoleParams>,
): UseMutationResult<void, Error, EditRoleParams> => {
  return useAuthenticatedMutation<void, Error, EditRoleParams>(
    async ({ userId, accountType }, token) =>
      editAccountType(chatbotName, userId, accountType, token),
    {
      ...options,
      onSuccess: () => {
        void queryClient.invalidateQueries({ queryKey: QueryKey.userList })
      },
    },
  )
}
