import { queryClient } from "@app/App/queryClient"
import { UseMutationOptions, UseMutationResult } from "@tanstack/react-query"

import { API_V1, buildAuthHeader, REST_URL } from "../api"
import { QueryKey } from "../queryKey"
import { useAuthenticatedMutation } from "../useAuthenticatedMutation"

export type UserId = string

const deleteUser = async (
  chatbotName: string,
  userId: UserId,
  token: string,
): Promise<void> => {
  const path = `${REST_URL}${API_V1}/users/role`
  const response = await fetch(path, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      ...buildAuthHeader(token),
    },
    body: JSON.stringify({
      userId,
      externalId: chatbotName,
    }),
  })

  if (!response.ok) {
    throw new Error("User delete failed")
  }
}

export const useUserDeleteQuery = (
  chatbotName: string,
  options?: UseMutationOptions<void, Error, UserId>,
): UseMutationResult<void, Error, UserId> => {
  return useAuthenticatedMutation<void, Error, UserId>(
    async (userId, token) => deleteUser(chatbotName, userId, token),
    {
      ...options,
      onSuccess: () => {
        void queryClient.invalidateQueries({ queryKey: QueryKey.userList })
      },
    },
  )
}
