import { UseMutationOptions, UseMutationResult } from "@tanstack/react-query"

import { API_V1, buildAuthHeader, REST_URL } from "../api"
import { useAuthenticatedMutation } from "../useAuthenticatedMutation"

type GenerateLinkParams = {
  role_id: string
  tenant_name: string
  role_type: string
  chatbots: string[]
}

type QueryResultData = { link: string }

const generateLink = async (
  params: GenerateLinkParams,
  token: string,
): Promise<QueryResultData> => {
  const path = `${REST_URL}${API_V1}/invitation/link/create`
  const response = await fetch(path, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...buildAuthHeader(token),
    },
    body: JSON.stringify({ ...params }),
  })

  if (!response.ok) {
    throw new Error("Failed to generate invitation link")
  }

  const results = (await response.json()) as QueryResultData
  return results
}

export const useInvitationGenerateLinkQuery = (
  options?: UseMutationOptions<QueryResultData, Error, GenerateLinkParams>,
): UseMutationResult<QueryResultData, Error, GenerateLinkParams> => {
  return useAuthenticatedMutation<QueryResultData, Error, GenerateLinkParams>(
    async (params, token) => generateLink(params, token),
    options,
  )
}
