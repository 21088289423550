import { QuestionsParams, QuestionsResponseAPI } from "@app/model/questions"
import {
  QueryFunction,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query"

import { API_V1, buildAuthHeader, REST_URL_CHATBOT } from "../api"
import { QueryKey } from "../queryKey" // Ensure this exports a type, not just a constant
import { useAuthenticatedQuery } from "../useAuthenticatedQuery"

type QueryResultData = QuestionsResponseAPI
type QueryKeyType = ReturnType<typeof QueryKey.questionsList>

export const getQuestionList: QueryFunction<
  QueryResultData,
  QueryKeyType
> = async ({ queryKey, meta }): Promise<QueryResultData> => {
  const [_, chatbotName, params] = queryKey
  const { page, page_size, timeframe, sort_by, sort_order } = params

  const paramsString = new URLSearchParams({
    page,
    page_size,
    timeframe,
    sort_by,
    sort_order,
    role_id: chatbotName,
  }).toString()

  const questionsPath = `${REST_URL_CHATBOT}${API_V1}/questions/?${paramsString}`

  const token = meta?.token as string

  const response = await fetch(questionsPath, {
    method: "GET",
    headers: {
      ...buildAuthHeader(token),
    },
  })

  if (!response.ok) {
    throw Error("Get questions fails")
  }

  const results = (await response.json()) as QuestionsResponseAPI
  return results
}

export const useQuestionsQuery = (
  chatbotName: string,
  params: QuestionsParams,
  options?: Omit<
    UseQueryOptions<QueryResultData, Error, QueryResultData, QueryKeyType>,
    "queryKey"
  >,
): UseQueryResult<QueryResultData, Error> => {
  const queryKey = QueryKey.questionsList(chatbotName, params)

  return useAuthenticatedQuery<QueryResultData, Error, QueryKeyType>(
    queryKey,
    getQuestionList,
    options,
  )
}
