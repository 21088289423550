{
  "ask_ai": {
    "back_to_all_questions": "Back To All Questions",
    "info": "You can ask the Ai Chat Bot anything about the files that are uploaded.",
    "placeholder": "Ask Ai here..."
  },
  "common": {
    "bot_answer": "Bot’s answer",
    "cancel": "Cancel",
    "chatbot": "Chatbot",
    "delete": "Delete",
    "no_data": "No data",
    "not_available": "N/A",
    "page_not_found": "Page not found.",
    "something_went_wrong": "Something went wrong",
    "user_question": "User’s question:"
  },
  "conversation": {
    "all_questions": "All questions ({{ questionsCount }})",
    "table": {
      "header": {
        "created_at": "Created At",
        "question": "Question",
        "rated": "Rated"
      }
    },
    "upload_new_file": "Upload a new file to load questions."
  },
  "date_filter": {
    "last_24_hours": "Last 24 Hours",
    "last_30_days": "Last 30 Days",
    "last_7_days": "Last 7 Days",
    "this_year": "Year"
  },
  "form_validation": {
    "confirm_password": "Passwords don't match",
    "password": {
      "special": "Password must have at least 1 special character",
      "uppercase": "Password must have at least 1 upper case character",
      "short": "Password must be at least 8 characters long"
    },
    "email_invalid": "Enter a valid email",
    "field_invalid": "Field invalid",
    "field_required": "Field required",
    "number_invalid": "Must be a number"
  },
  "header": {
    "evolv_gpt_link": "Go to web app",
    "profile": {
      "bot": "Bot",
      "dropdown": {
        "log_in": "Log In",
        "log_out": "Log Out",
        "settings": "Settings"
      },
      "hi": "Hi",
      "isop": "ISOP"
    },
    "tabs": {
      "home": "Home",
      "user_management": "User Management"
    }
  },
  "login_screen": {
    "create_account": "Create Account",
    "confirm_password": "Confirm Password",
    "user_role": "User Role",
    "roles": {
      "user": "User",
      "admin": "Admin"
    },
    "email_address": "Email Address",
    "enter_your_data": "Create an Account",
    "firstName": "First Name",
    "lastName": "Last Name",
    "next": "Next",
    "password": "Password",
    "sign_in_eva_dashboard": "Sign in to ChatBot Dashboard"
  },
  "modal": {
    "delete": "Are you sure you want to delete, {{ file }} file from the library?"
  },
  "uploadFile": {
    "button": "Upload File From PC",
    "no_files": "No Files have been uploaded.",
    "total_files_uploaded": "Total Files Uploaded",
    "uploaded": "File successfully uploaded.",
    "uploading": "New File Is being uploaded..."
  },
  "user_management": {
    "add_user": {
      "email_address": "Email Address",
      "roles": {
        "admin": "Admin",
        "user": "User"
      },
      "title": "Add User",
      "user_role": "User Role"
    },
    "all_users": "Users ({{ usersCount }})",
    "copy_invitation_link": {
      "title": "Copy Invitation Link"
    },
    "make_admin": {
      "confirm": "Please confirm if you want to make <strong>{{ email }}</strong> an admin?",
      "title": "Add Admin"
    },
    "make_user": {
      "confirm": "Please confirm if you want to demote <strong>{{ email }}</strong>?",
      "title": "Demote Admin to User"
    },
    "no_user_data": "No users data",
    "remove_user": {
      "confirm": "Please confirm if you want to remove <strong>{{ email }}</strong>",
      "title": "Remove User"
    },
    "table": {
      "header": {
        "account_type": "Account Type",
        "email": "Email",
        "name": "Name"
      }
    }
  }
}
