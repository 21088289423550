import React, { Dispatch, SetStateAction, useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import { useAccountTypeEditQuery } from "@app/api/users/useAccountTypeEditQuery"
import { useUserDeleteQuery } from "@app/api/users/useUserDeleteQuery"
import { ChevronDown, CloseIcon, TrashIcon } from "@app/assets/Icons"
import { useChatbotContext } from "@app/context/ChatbotContext"
import { SortOption, SortOrder } from "@app/model/sorting"
import { User } from "@app/model/user"
import FormSubmitButton from "@app/uiKit/FormSubmitButton"
import {
  Alert,
  Backdrop,
  createTheme,
  MenuItem,
  Paper,
  Snackbar,
  SnackbarCloseReason,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  ThemeProvider,
} from "@mui/material"
import cn from "classnames"

const theme = createTheme({
  components: {
    MuiTableContainer: {
      styleOverrides: {
        root: {
          boxShadow: "none",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: 0,
          border: 0,
        },
        head: {
          fontWeight: "bold",
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {},
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          "&.Mui-active": {
            color: "inherit",
          },
        },
      },
    },
  },
})

type UsersTableProps = {
  users: User[]
  selectedSortOption: SortOption
  setSelectedSortOption: Dispatch<SetStateAction<SortOption>>
}

type ConfirmationModal = {
  action: string
  user: User
  accountType?: string
}

enum SnackbarAction {
  delete = "DELETE",
  edit = "EDIT",
}

enum ConfirmationModalAction {
  delete = "DELETE",
  makeAdmin = "ADMIN",
  makeUser = "USER",
}

const UsersTable = ({
  users,
  selectedSortOption,
  setSelectedSortOption,
}: UsersTableProps): JSX.Element => {
  const { t } = useTranslation()
  const { chatbotName } = useChatbotContext()

  const [confirmationModal, setConfirmationModal] =
    useState<ConfirmationModal>()

  const [snackbarAction, setSnackbarAction] = useState<
    SnackbarAction | undefined
  >()

  const { isPending: isDeletePending, mutate: mutateDelete } =
    useUserDeleteQuery(chatbotName)

  const { isPending: isEditPending, mutate: mutateEdit } =
    useAccountTypeEditQuery(chatbotName)

  const handleSort = (property: string): void => {
    const isAsc =
      selectedSortOption.sort_by === property &&
      selectedSortOption.sort_order === SortOrder.asc
    setSelectedSortOption({
      sort_by: property,
      sort_order: isAsc ? SortOrder.desc : SortOrder.asc,
    })
  }

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason,
  ): void => {
    if (reason === "clickaway") {
      return
    }

    setSnackbarAction(undefined)
  }

  const handleUserDelete = (userId: string): void => {
    mutateDelete(userId)
    setConfirmationModal(undefined)
    setSnackbarAction(SnackbarAction.delete)
  }

  const handleEditAccountType = (): void => {
    if (confirmationModal?.accountType) {
      mutateEdit({
        userId: confirmationModal.user.id,
        accountType: confirmationModal.accountType,
      })
      setConfirmationModal(undefined)
      setSnackbarAction(SnackbarAction.edit)
    }
  }

  const handleOnAccountTypeChange = (
    event: { target: { value: string } },
    user: User,
  ): void => {
    const accountType = event.target.value
    if (accountType === "user") {
      setConfirmationModal({
        action: ConfirmationModalAction.makeUser,
        user,
        accountType,
      })
    } else if (event.target.value === "admin") {
      setConfirmationModal({
        action: ConfirmationModalAction.makeAdmin,
        user,
        accountType,
      })
    }
  }

  const cellHeadClasses =
    " whitespace-nowrap text-grey-100 border-b border-l-0 border-r-0 border-t-0 border-grey-100 border-solid py-4 px-3 mx-5"
  const cellBodyClasses =
    "flex items-center border-b border-l-0 border-r-0 border-t-0 border-grey-100 border-solid py-4 px-3 mx-5 h-[56px]"
  const cellIconClasses =
    "flex justify-center items-center border border-grey-100 border-solid m-auto w-[22px] h-[22px] rounded-[50%]"

  const textEllipsisClasses = "whitespace-nowrap overflow-hidden text-ellipsis"

  const disabledRowStyle = (active: boolean): { color: string } => {
    return { color: active ? "inherit" : "#91A3A3" }
  }

  const tableHeaderConfig = [
    {
      key: "email",
      name: t("user_management.table.header.email"),
      sorter: true,
    },
    {
      key: "full_name",
      name: t("user_management.table.header.name"),
      sorter: true,
    },
    {
      key: "account_type",
      name: t("user_management.table.header.account_type"),
      sorter: true,
    },
  ]

  const renderConfirmationModalContent = (): JSX.Element => {
    if (confirmationModal?.action === ConfirmationModalAction.delete) {
      return (
        <>
          <p>
            <Trans
              i18nKey={t("user_management.remove_user.confirm", {
                email: confirmationModal.user.email,
              })}
              components={{ strong: <strong /> }}
            />
          </p>
          <FormSubmitButton
            testID="remove-user-button"
            onPress={() => handleUserDelete(confirmationModal.user.id)}
            isLoading={isDeletePending}
            text={t("user_management.remove_user.title")}
          />
        </>
      )
    } else if (confirmationModal?.action === ConfirmationModalAction.makeUser) {
      return (
        <>
          <p>
            <Trans
              i18nKey={t("user_management.make_user.confirm", {
                email: confirmationModal.user.email,
              })}
              components={{ strong: <strong /> }}
            />
          </p>
          <FormSubmitButton
            testID="make-user-button"
            onPress={() => handleEditAccountType()}
            isLoading={isEditPending}
            text={t("user_management.make_user.title")}
          />
        </>
      )
    } else if (
      confirmationModal?.action === ConfirmationModalAction.makeAdmin
    ) {
      return (
        <>
          <p>
            <Trans
              i18nKey={t("user_management.make_admin.confirm", {
                email: confirmationModal.user.email,
              })}
              components={{ strong: <strong /> }}
            />
          </p>
          <FormSubmitButton
            testID="make-admin-button"
            onPress={() => handleEditAccountType()}
            isLoading={isEditPending}
            text={t("user_management.make_admin.title")}
          />
        </>
      )
    }
    return <></>
  }

  return (
    <div className="w-full px-6 mb-6">
      <ThemeProvider theme={theme}>
        <TableContainer component={Paper}>
          <Table className="min-w-[1000px]">
            <TableHead className="text-grey-100">
              <TableRow>
                <TableCell />
                {tableHeaderConfig.map(item => (
                  <TableCell key={item.key} className="font-bold">
                    {item.sorter ? (
                      <div className={cellHeadClasses}>
                        <TableSortLabel
                          active={selectedSortOption.sort_by === item.key}
                          direction={
                            selectedSortOption.sort_by === item.key
                              ? selectedSortOption.sort_order
                              : SortOrder.asc
                          }
                          onClick={() => handleSort(item.key)}
                          IconComponent={ChevronDown}
                        >
                          <span className="mr-5">{item.name}</span>
                        </TableSortLabel>
                      </div>
                    ) : (
                      <div className={cellHeadClasses}>{item.name}</div>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user, index) => (
                <TableRow key={user.id}>
                  <TableCell
                    className="w-[26px]"
                    sx={disabledRowStyle(user.active)}
                  >
                    <div className={cn(cellIconClasses, "count-icon")}>
                      {++index}
                    </div>
                  </TableCell>
                  <TableCell sx={disabledRowStyle(user.active)}>
                    <div className={cn(cellBodyClasses)}>
                      <span
                        className={cn("max-w-[300px]", textEllipsisClasses)}
                      >
                        {user.email}
                      </span>
                    </div>
                  </TableCell>
                  <TableCell sx={disabledRowStyle(user.active)}>
                    <div className={cellBodyClasses}>
                      <span
                        className={cn("max-w-[120px]", textEllipsisClasses)}
                      >
                        {user.userName || "-"}
                      </span>
                    </div>
                  </TableCell>
                  <TableCell sx={disabledRowStyle(user.active)}>
                    <div className={cellBodyClasses}>
                      <TextField
                        className="w-full"
                        variant="standard"
                        disabled={!user.active}
                        sx={{
                          "& .MuiInputBase-root.MuiInput-root": {
                            height: "48px",
                            fontSize: "14px",
                            ...disabledRowStyle(user.active),
                          },
                          "& .MuiInputBase-root.MuiInput-root.MuiInput-underline::before":
                            {
                              borderBottom: "none",
                            },
                          "& .MuiInputBase-root.MuiInput-root.MuiInput-underline::after":
                            {
                              borderBottom: "none",
                              transform: "none",
                            },
                          "& .MuiSelect-select.MuiSelect-standard.MuiInputBase-input.MuiInput-input:focus":
                            {
                              backgroundColor: "#ffffff",
                            },
                          "& .MuiFormLabel-root.MuiInputLabel-root.Mui-focused":
                            {
                              color: "initial",
                            },
                        }}
                        name="userRole"
                        select
                        value={user.accountType}
                        onChange={e => handleOnAccountTypeChange(e, user)}
                      >
                        <MenuItem value="user">
                          {t("user_management.add_user.roles.user")}
                        </MenuItem>
                        <MenuItem value="admin">
                          {t("user_management.add_user.roles.admin")}
                        </MenuItem>
                      </TextField>
                    </div>
                  </TableCell>
                  <TableCell>
                    <div
                      className="cursor-pointer"
                      onClick={() =>
                        setConfirmationModal({
                          action: ConfirmationModalAction.delete,
                          user,
                        })
                      }
                    >
                      <TrashIcon />
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </ThemeProvider>
      <Backdrop
        open={Boolean(confirmationModal)}
        sx={{
          color: "#24272F",
          zIndex: "1",
          backdropFilter: "blur(2px)",
          backgroundColor: "#0000004d",
        }}
      >
        <div className="flex w-full h-full items-center justify-center">
          <div className="w-[30%] rounded-[32px] bg-white p-6 sm:py-12 sm:px-16 relative text-left">
            <div
              className="absolute right-[30px] top-[25px] cursor-pointer"
              onClick={() => setConfirmationModal(undefined)}
            >
              <CloseIcon />
            </div>

            {renderConfirmationModalContent()}
          </div>
        </div>
      </Backdrop>
      <Snackbar
        open={Boolean(snackbarAction)}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbarAction === SnackbarAction.edit &&
            "The user successfully edited!"}
          {snackbarAction === SnackbarAction.delete &&
            "The user successfully removed!"}
        </Alert>
      </Snackbar>
    </div>
  )
}

export default UsersTable
